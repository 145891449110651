.tour-hero {

    background: rgb(236, 207, 52);
    background: linear-gradient(90deg, gold 0%, rgb(250, 225, 83) 7%, rgb(244, 220, 82) 11%, rgba(255, 255, 255, 1) 100%);
    height: 4rem;
    width: 100%;
    margin-bottom: 5%;
}
.link{
    text-decoration: none; color: black;
}
.tour-card{
    border-radius: 1.2rem;
}
a{
    text-decoration: none;
}