@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Bangers&family=Handjet:wght@300&display=swap');

.viewCard {
    height: 20rem !important;
    text-align: end;
    line-height: 5;
}

.my-h2 {
    font-family: 'Audiowide', cursive;
    font-family: 'Bangers', cursive;
    font-size: xx-large;
    color: goldenrod;
}

.my-h6 {
    font-size: x-large !important;
    font-family: 'Audiowide', cursive;
    font-family: 'Bangers', cursive;
}
.bbb{
    background: url('https://ik.imagekit.io/ghsourav/FriendsHotel/hero/237055550.jpg_k=8e4ead78bf905fff447d7f4b84327f27b43dfaa2ef7e5d4176493fc1e13e7adc&o=');
    background-size: cover;
    background-repeat: no-repeat;
}
.gangtok{
    background: url('https://res.cloudinary.com/ghsourav/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1688796997/HotelbellaCasa/hero/64_qwmcfm.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.fine-dinning {
    background: url('https://ik.imagekit.io/ghsourav/FriendsHotel/GhSouravView/buffet-315691_1280.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.travel {
    background: url('https://ik.imagekit.io/ghsourav/FriendsHotel/GhSouravView/Honda-car.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}