@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

* {
    font-family: "Open Sans", sans-serif;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}
.display-h1{
    font-size: 2.5rem !important;
    line-height: 56px !important;
    font-weight: 900;
}

.hero {
    /* max-height: 50rem; */
    height: 100vh;
    width: 100%;
    position: relative;
    /* background-color: #f3fcff; */
    background-image: url('../../loader/hk.png');

}

.hero-m {
    /* max-height: 50rem; */
    height: 50vh;
    width: 100%;
    position: relative;
    /* background: #f3fcff; */
    background-image: url('../../loader/hk.png');

}

.jumbotron {
    height: 90%;
    /* background: #f3fcff; */
    /* background: url('https://res.cloudinary.com/ghsourav/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1688796997/HotelbellaCasa/hero/64_qwmcfm.jpg'); */
}
.n{
    /* background-image: url('../../logo/snow-3265295_1280.jpg'); */
    background-image: url('https://promos.makemytrip.com/Hotels_product/Luxe/bkg-dt.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: lightgoldenrodyellow;
}

.t-box {
    background: rgba(0, 0, 0, 0.434);
    border-radius: 1rem;
    padding: 0.3rem;
}


.try {
    /* height: 90%; */
    background: #fefff2;
    /* background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgb(18, 175, 199) 50%, rgb(223, 101, 242) 100%); */
    /* background: linear-gradient(90deg, #eee67a41 52%, rgba(202, 130, 15, 0.266) 100%), url('https://ik.imagekit.io/ghsourav/FriendsHotel/GhSouravView/cherry-blossom.jpg?updatedAt=1691614724687'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    /* color: ghostwhite; */
    /* clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 90%); */
}
.dpnone{
    display: none;
}
.hero-loader {
    height: 150vh;
}
.img-random{
    width: 35rem;
    transform: rotate(6deg);
}

.add {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    margin: 0 auto;
}

.hero-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    background: -webkit-linear-gradient(to right, #FFF4A3, Brown);
    background: linear-gradient(to right, #FFF4A3, Brown);
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;

}