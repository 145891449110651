.sec1 {
    background: url("https://ik.imagekit.io/ghsourav/FriendsHotel/GhSouravView/Fly-View.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
.sm-sec1 {
    height: 15rem !important;
}
.xl-sec1 {
    height: 25rem !important;
}
.sec1-h1 {
    font-weight: bold;
    line-height: 10rem;
    font-size: xx-large !important;
}

.hh{
    height: 120%;
}
