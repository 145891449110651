.Hotelhero {
    width: 100% !important;
    /* background: url("../../loader/Skateboarding.gif"); */
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    height: 50rem !important;
}



.HotelheroRinchenpong {
    /* background: url("https://ik.imagekit.io/ghsourav/FriendsHotel/Rinchenpong/230720312.jpg_k=a71f63f07505e240ac7908b27400aa7d68a3cefa71025a7333a2fc006535f471&o=?updatedAt=1691311758191"); */
    background-repeat: no-repeat;
    background-size: cover;
    background: url("https://ik.imagekit.io/ghsourav/FriendsHotel/Rinchenpong/Norbulingka-Retreat/Ghsourav-.jpeg?updatedAt=1694093879179");
    background-size: cover;
}

.HotelheroBellaCasa {
    /* background: url("https://ik.imagekit.io/ghsourav/FriendsHotel/Rinchenpong/230720312.jpg_k=a71f63f07505e240ac7908b27400aa7d68a3cefa71025a7333a2fc006535f471&o=?updatedAt=1691311758191"); */
    background-repeat: no-repeat;
    background-size: cover;
    background: url("https://res.cloudinary.com/ghsourav/image/upload/v1688796996/HotelbellaCasa/hero/93_p0nhfq.jpg");
    background-size: cover;
}

.ghosh-h1 {
    color: aqua;
}


.heroimg {
    width: 80% !important;
    height: 68%;
    border-radius: 15% 12%;
    margin: 10%;
    border: 0.5rem solid snow;
}

.bg-my {
    background-color: #f3fcff;
}
.bg-myopacity{
    background-color: rgba(182, 177, 177, 0.234);
}

.mt-4rem {
    margin-top: 25% !important;
}

.amenity-div {
    border: 9px solid green;
    height: 20px;
    margin: 20px;
}

.service {

    height: 10rem !important;
    width: 10rem !important;
}

#mymap {
    margin: 0 !important;
    padding: 0 !important;
}

.mymapcard {
    height: 25rem;
    padding: 0 !important;
}

.pho {
    height: 14rem !important;
    width: 20rem !important;
}
.a-text-decoration-none{
    text-decoration: none;
    padding: 1rem;
}