
.hotel-footer {
    background-color: #333;
    color: #fff;
    padding: 30px 0;
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  address {
    font-style: normal;
  }
  

.text-hotel{
    text-decoration: none;
}  