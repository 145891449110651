.info {
    height: 100%;
    background: gainsboro;
    border: 0.5rem dotted #fff;
}
.bg-gainsboro{
    background: gainsboro;
}
.box {
   
    height: 100%;
}

/* .text-hotel {
    color: #d29751;
} */

.titte-box {
    min-height: 30rem !important;
    height: 100%;
}
.bg-Rinchenpong-view{
    background: url("https://ik.imagekit.io/ghsourav/FriendsHotel/hero/237055550.jpg_k=8e4ead78bf905fff447d7f4b84327f27b43dfaa2ef7e5d4176493fc1e13e7adc&o=?updatedAt=1691304187309");
    background-repeat: no-repeat;
    background-size: cover;
   
}
/* https://res.cloudinary.com/ghsourav/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1688796997/HotelbellaCasa/hero/64_qwmcfm.jpg */
.bg-gangtok-view{
    background: url("https://res.cloudinary.com/ghsourav/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1688796997/HotelbellaCasa/hero/64_qwmcfm.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    
}