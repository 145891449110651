/* @import url('https://fonts.googleapis.com/css2?family=Handjet:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Ysabeau+Infant:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Bangers&family=Handjet:wght@300&family=Recursive&display=swap');

.navbar {
  background: white;
  height: 5rem !important;
}

.goldenbar {
  background: goldenrod;
  height: 1.5rem;
  color: beige;
  text-align: center;
  padding: 0 0.2rem;
  
}
.goldenbar-link{
  color: beige;
  background-color: transparent;
  text-decoration: none;
}

.text-hotel {
  color: goldenrod;
}

.display-none {
  display: none;
}

.bg-loader {
  width: 100% !important;
  background: url("./logo/Loader.gif");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.Handjet-h1 {
  /* font-family: 'Handjet', cursive !important;
  font-family: 'Audiowide', cursive;
font-family: 'Bangers', cursive;
font-family: 'Handjet', cursive; */
  font-family: 'Recursive', sans-serif;
}

.o_img {
  width: 50%;
  box-shadow: 10px 10px rgba(179, 172, 172, 0.403);
  margin-bottom: 20px;
  border-radius: 3%;
}

.myo {
  font-size: xx-large;
  font-weight: 600;
}

.Ysabeau {
  font-family: 'Ysabeau Infant', sans-serif;

}

.hahahaha {
  height: 14.5rem !important;
}